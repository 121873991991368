var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted)?_c('div',{staticClass:"drafts-list"},[_c('page-title',{attrs:{"tags":_vm.boardTokenCode === 'default' ? _vm.BRAND_TAG : null}}),(_vm.boardTokenCode !== 'default')?_c('div',[_c('a-drawer',{attrs:{"position-right":"","visible":_vm.isActive,"mask-closable":"","closable":"","width":_vm.drawerWidth,"placement":"right","title":_vm.$t('planner_page_post_preview')},on:{"close":function($event){_vm.isActive = false}}},[(_vm.selectedDraft)?_c('div',{staticClass:"p-5"},[(_vm.selectedDraft.type === 'Post')?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args':{
              'model': _vm.selectedDraft,
              'key':'variants',
              'prefix': 'post_',
              'validation': 'required',
              'availableButtonsTypes': _vm.defaultMessageEditorButtons,
              'settingsStructure': {
                'disable_link_preview': false,
                'disable_notify': false,
                'pin': false,
                'remove_previous': false,
              },
              'targetToUpload': function () { return _vm.boardUploadTarget(_vm.boardTokenIdCode); },
              'hasMedia': true,
              'disabled': true,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]
            }
          }}}):_c('Quizes',{attrs:{"disabled":true},model:{value:(_vm.selectedDraft),callback:function ($$v) {_vm.selectedDraft=$$v},expression:"selectedDraft"}})],1):_vm._e()]),(_vm.draftPosts.length)?_c('a-row',{attrs:{"type":"flex","gutter":[16,16]}},_vm._l((_vm.draftPosts),function(draft,index){return _c('a-col',{key:draft.key.token_code,attrs:{"xs":24,"md":8}},[_c('a-card',{attrs:{"title":((_vm.$t('board_draft_list_index')) + "  № " + (index + 1))}},[_vm._v(" "+_vm._s(_vm.draftMessageText(draft))+" "),_c('template',{slot:"actions"},[_c('a-icon',{attrs:{"type":"eye"},on:{"click":function($event){return _vm.handlePreviewButtonSelect(draft)}}}),_c('a-icon',{attrs:{"type":"plus"},on:{"click":function($event){return _vm.createPostFromDraft(draft)}}}),_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteDraftClickHandler(draft.key)}}})],1)],2)],1)}),1):_c('empty-data')],1):_c('div',{staticClass:"relative",staticStyle:{"min-height":"300px","z-index":"2!important"}},[_c('NotAvailableOptionsOverlay',{attrs:{"description":_vm.$t('option_description_board_drafts'),"tags":_vm.BRAND_TAG}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }